<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5848 3.58085C13.9595 3.20835 14.4664 2.99927 14.9948 2.99927C15.5232 2.99927 16.0301 3.20835 16.4048 3.58085L16.4069 3.58295L21.409 8.58506C21.7815 8.95979 21.9906 9.46669 21.9906 9.99506C21.9906 10.523 21.7819 11.0295 21.4099 11.4042C21.4096 11.4045 21.4093 11.4048 21.409 11.4051L13.8957 19.0001H18.9998C19.5521 19.0001 19.9998 19.4478 19.9998 20.0001C19.9998 20.5523 19.5521 21.0001 18.9998 21.0001H11.5004C11.5 21.0001 11.4996 21.0001 11.4992 21.0001H8.49982C8.23093 21.0001 7.97338 20.8918 7.78527 20.6996L3.58061 16.4051C3.57955 16.404 3.57849 16.4029 3.57743 16.4019C3.20692 16.0274 2.99902 15.5219 2.99902 14.9951C2.99902 14.4667 3.20811 13.9598 3.58061 13.5851L3.58271 13.583L13.5827 3.58295L13.5848 3.58085ZM11.0824 19.0001L19.9889 9.99679L19.9906 9.99506L14.9948 4.99927L4.99902 14.9951L5.00438 15.0005L8.92024 19.0001H11.0824Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9931 6.29289C11.3836 5.90237 12.0168 5.90237 12.4073 6.29289L18.7073 12.5929C19.0978 12.9834 19.0978 13.6166 18.7073 14.0071C18.3168 14.3976 17.6836 14.3976 17.2931 14.0071L10.9931 7.70711C10.6026 7.31658 10.6026 6.68342 10.9931 6.29289Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconEraser'
}
</script>